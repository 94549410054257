import React from "react";
import PropTypes from "prop-types";

const EventAnalyticsGoogleSessions = props => {
  const { token } = props;

  const timeline = new gapi.analytics.googleCharts.DataChart({
    reportType: "ga",
    query: {
      ids: "ga:228128977",
      dimensions: "ga:date",
      metrics: "ga:sessions",
      "start-date": "7daysAgo",
      "end-date": "yesterday"
    },
    chart: {
      type: "LINE",
      container: "timeline-sessions"
    }
  });

  gapi.analytics.auth.authorize({
    serverAuth: {
      access_token: token
    }
  });
  timeline.execute();

  return (
    <div>
      <div id="timeline-sessions" />
    </div>
  );
};

EventAnalyticsGoogleSessions.propTypes = {
  token: PropTypes.string.isRequired
};

export default EventAnalyticsGoogleSessions;
