import React from "react";
import PropTypes from "prop-types";

import { v4 as uuidv4 } from "uuid";

import { renderButton } from "@shared/FormUtils";

const PeopleEventParticipantsImportResult = props => {
  const { results, callbackSuccess } = props;

  const generateKey = (prefix = "") => {
    return `${prefix}-${uuidv4()}`;
  };

  const renderSuccess = () => {
    if (results.success.length === 0) return <div>No successful imports</div>;
    return results.success.map(result => (
      <div key={generateKey(result)}>
        <span className="text-green-500">{result}</span>
      </div>
    ));
  };

  const renderFailed = () => {
    if (results.failed.length === 0) return <div>No failed imports</div>;
    return results.failed.map(result => (
      <div key={generateKey(result)}>
        <span className="text-red-500">{result}</span>
      </div>
    ));
  };

  const renderErrors = () => {
    if (results.errors.length === 0) return <div>No errors</div>;
    return results.errors.map(result => (
      <div key={generateKey(result.row_number)}>
        <span className="text-red-500">{`Row ${result.row_number}: `}</span>
        <span>{`${result.email}: `}</span>
        {result.error}
      </div>
    ));
  };

  return (
    <div className="mb-8 w-full">
      <div className="my-4">
        <div className="text-sg-orange text-lg font-normal mt-4 mb-2">
          {"Imported Successfully"}
        </div>
        {renderSuccess()}
      </div>
      <div className="my-4">
        <div className="text-sg-orange text-lg font-normal mt-4 mb-2">
          {"Failed to Import"}
        </div>
        {renderFailed()}
      </div>
      <div className="my-4">
        <div className="text-sg-orange text-lg font-normal mt-4 mb-2">
          {"Error Log"}
        </div>
        {renderErrors()}
      </div>
      <div className="my-4">{renderButton("Done", callbackSuccess)}</div>
    </div>
  );
};

PeopleEventParticipantsImportResult.propTypes = {
  callbackSuccess: PropTypes.func.isRequired,
  results: PropTypes.object.isRequired
};

export default PeopleEventParticipantsImportResult;
