import React, { useState } from "react";
import PropTypes from "prop-types";

import { useHistory, Prompt } from "react-router-dom";

import ContentFrame from "@shared/ContentFrame";
import PageHeader from "@shared/PageHeader";
import Tabs from "@shared/tabs/Tabs";

import PeopleEventParticipantsImportForm from "./PeopleEventParticipantsImportForm";
import PeopleEventParticipantsImportMapping from "./PeopleEventParticipantsImportMapping";
import PeopleEventParticipantsImportResult from "./PeopleEventParticipantsImportResult";

const PeopleEventParticipantsImport = (props) => {
  const { back } = props;
  const [stage, setStage] = useState(0);
  const [sheet, setSheet] = useState(null);
  const [headerValues, setHeaderValues] = useState(null);
  const [results, setResults] = useState([]);
  const history = useHistory();

  const successStage1 = (data) => {
    setSheet(data.sheet);
    setHeaderValues(data.header_values);
    setStage(1);
  };

  const successStage2 = (results) => {
    setResults(results);
    setStage(2);
  };

  const successStage3 = () => {
    history.push(".");
  };

  const renderHeader = () => {
    if (stage === 2) return <></>;
    return (
      <>
        <PageHeader text="Import Participants" />
        <div className="my-2">
          {
            "You can upload participants through a spreadsheet. Simply upload the spreadsheet and map the imported data to the existing data points."
          }
        </div>
      </>
    );
  };

  const renderStage = () => {
    switch (stage) {
      case 0:
        return <PeopleEventParticipantsImportForm back={back} callbackSuccess={successStage1} />;
      case 1:
        return (
          <PeopleEventParticipantsImportMapping
            back={back}
            sheet={sheet}
            headerValues={headerValues}
            callbackSuccess={successStage2}
          />
        );
      case 2:
        return <PeopleEventParticipantsImportResult results={results} callbackSuccess={successStage3} />;
      default:
        console.log("Invalid stage");
        return <></>;
    }
  };

  return (
    <div className="w-full">
      {stage === 1 ? <Prompt when={true} message="Are you sure you want to leave? There may be unsaved data" /> : <></>}
      <Tabs
        tabs={[
          {
            id: "default",
            name: "Participants",
            onClick: () => {
              history.push(".");
            }
          }
        ]}
        activeTabId={"default"}
      />
      <ContentFrame>
        {renderHeader()}
        {renderStage()}
      </ContentFrame>
    </div>
  );
};

PeopleEventParticipantsImport.defaultProps = {
  back: () => {}
};

PeopleEventParticipantsImport.propTypes = {
  back: PropTypes.func
};

export default PeopleEventParticipantsImport;
