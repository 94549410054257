import React from "react";

const EventAnalyticsNewrelicRpm = () => {
  return (
    <div>
      <div className="sg-mgmt-content-header">
        <h2>Requests Per Minute</h2>
      </div>
      <div>
        <iframe
          src="https://chart-embed.service.newrelic.com/herald/8dfbe89e-012d-4305-8862-b7bc440e4d1d?height=400px&timepicker=true"
          title="NewRelic RPM"
          scrolling="no"
          style={{
            width: "100%",
            border: "0",
            height: "500px",
            overflow: "hidden"
          }}
        />
      </div>
    </div>
  );
};

export default EventAnalyticsNewrelicRpm;
