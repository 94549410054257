import React, { useContext, useEffect, useState } from "react";
import EventContext from "@event/EventContext";
import axios from "axios";
import PageHeader from "@shared/PageHeader";
import urljoin from "url-join";
import { renderCreateButton } from "@shared/FormUtils";
import { alertHttpError, alertSuccess } from "@shared/Alerts";
import Loading from "@shared/Loading";
import GrowlTable from "@shared/GrowlTable";
import UploadStaticFileModal from "./UploadStaticFileModal";
import EventUserContext from "@event/EventUserContext";
import { Link } from "@mui/material";
import { formatTime } from "@shared/TimeUtils";
import { useConfirm } from "material-ui-confirm";

const StaticFilesIndex = props => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const [staticFiles, setStaticFiles] = useState([]);
  const [uploadFileModalOpen, setUploadFileModalOpen] = useState(false);
  const [fetched, setFetched] = useState(false);
  const confirm = useConfirm();
  useEffect(() => {
    const fetchStaticFiles = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "contents/static_files"));
        setStaticFiles(
          result.data["static_files"]
        );
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchStaticFiles();
  }, [apiRoot]);

  const humanFileSize = (bytes, dp=1) => {
    const thresh = 1024;
  
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
  
    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
    let u = -1;
    const r = 10**dp;
  
    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
  
  
    return bytes.toFixed(dp) + ' ' + units[u];
  }
  

  const columns = [
    {
      field: "original_filename",
      headerName: "Filename",
      flex: 1
    },
    {
      headerName: "Date Uploaded",
      field: "created_at",
      renderCell: params => formatTime(params.value, event.time_zone),
      flex: 1
    },
    {
      headerName: "File Size",
      field: "file_size",
      renderCell: params => humanFileSize(params.value, 1),
    },
    {
      field: "url",
      headerName: "Link",
      flex: 1,
      renderCell: params => {
        return (
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              console.log(params);
              navigator.clipboard.writeText(params.row.file_url);
              alertSuccess("URL copied to clipboard");
            }}
          >
            Copy Link
          </Link>
        );
      }, 
    },
    {
      headerName: "Actions",
      field: "actions",
      type: "actions",
      minWidth: 150,
      flex: 1,
      getActions: params => [
        renderDelete(params.row)
      ]
    }
  ]

  const renderDelete = (item) => {
    if (editEnabled()) {
      return (
        <>
          <span
            className="cursor-pointer"
            onClick={() => {
              attemptDelete(item);
            }}
          >
            Delete
          </span>
        </>
      );
    }
    return <></>;
  }

  const attemptDelete = (item) => {
    confirm({
      title: "Confirm removal",
      description:
        "Are you sure you want to remove this static file? This cannot be undone."
    }).then(() => {
      performDelete(item);
    })
    .catch(err => {
      alertError(err);
    });
  }

  performDelete = (item) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, `/contents/static_files/${item.id}`),
      method: "DELETE"
    })
    .then(response => {
      if (response.data.error == null) {
        setStaticFiles(staticFiles.filter(i => i.id !== item.id));
        alertSuccess("Entry deleted successfully");
      } else {
        alertError(response.data.error);
      }
    })
    .catch(error => {
      alertHttpError(error);
    });
  };


  const renderStaticFilesTable = () => {
    if (!fetched) {
      return <Loading />;
    }

    return (
      <GrowlTable
        columns={columns}
        items={staticFiles}
        sortColumn="name"
        sortDirection="asc"
        tableName={`${event.slug}-static-files`}
      />
    );
  }


  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.static_files_edit) {
      return false;
    }
    return true;
  };

  const renderUploadFileButton = () => {
    if (editEnabled()) {
      return (
        renderCreateButton("Upload Files", toggleUploadFileModal)
      );
    }
  };

  toggleUploadFileModal = () => {
    setUploadFileModalOpen(!uploadFileModalOpen);
  };

  const renderUploadFileModal = () => {
    return (
      <UploadStaticFileModal
        modalVisible={uploadFileModalOpen}
        resetModal={toggleUploadFileModal}
        callbackFailure={() => { }}
        callbackSuccess={files => {
          setStaticFiles([...staticFiles, ...files]);
        }}
      />
    );
  };

  return (
    <div>
      <PageHeader text="Static Files" />
      {renderUploadFileButton()}
      <br />
      <br />
      {renderStaticFilesTable()}
      {renderUploadFileModal()}
    </div>
  )
};

StaticFilesIndex.propTypes = {

};

export default StaticFilesIndex;
