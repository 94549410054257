import React from "react";

import EventMenuDropdown from "./EventMenuDropdown";
import EventMenuItem from "./EventMenuItem";

const EventMenu = () => {
  return (
    <div className="sg-mgmt-menu bg-gradient-to-l from-sky-50 to-slate-50">
      <EventMenuItem requiredPermissions={[]} icon="dashboard" link="/" title="Dashboard" />
      <EventMenuItem
        requiredPermissions={["participants_view"]}
        icon="attendees"
        link="/participants"
        title="Participants"
      />
      <EventMenuItem
        requiredPermissions={["communications_view"]}
        icon="communications"
        link="/communications"
        title="Communications"
      />
      <EventMenuItem
        requiredPermissions={["sessions_view"]}
        icon="sessions"
        link="/sessions"
        title="Sessions"
        activeLinks={["/sessions", "/session/"]}
      />
      <EventMenuDropdown
        icon="forms"
        title="Form Builder"
        links={[
          {
            title: "Registration", //TODO
            link: "/forms/registration",
            requiredPermissions: ["forms_view"] //Admin_view is not real, only accessable by admins
          },
          {
            title: "Surveys", //TODO
            link: "/forms/survey",
            requiredPermissions: ["forms_view"] //Admin_view is not real, only accessable by admins
          },
          {
            title: "Other", //TODO
            link: "/forms/miscellaneous",
            requiredPermissions: ["forms_view"] //Admin_view is not real, only accessable by admins
          }
        ]}
      />
      <EventMenuItem requiredPermissions={["housing_view"]} icon="housing" link="/housing" title="Housing" />
      <EventMenuItem requiredPermissions={["meetings_view"]} icon="meetings" link="/meetings" title="Meeting Tool" />
      <EventMenuItem requiredPermissions={["ticketing_view"]} icon="ticketing" link="/ticketing" title="Ticketing" />
      <EventMenuItem requiredPermissions={["analytics_view"]} icon="reports" link="/analytics" title="Reports" />
      <EventMenuDropdown
        icon="content"
        links={[
          {
            title: "Static Files",
            link: "/content/static_files",
            requiredPermissions: ["static_files_view"] //Admin_view is not real, only accessable by admins
          },
        ]}
        title="Content"
      />
      <EventMenuDropdown
        icon="settings"
        links={[
          {
            title: "Event",
            link: "/edit",
            requiredPermissions: ["admin_view"] //Admin_view is not real, only accessable by admins
          },
          {
            title: "Metadata",
            link: "/metadata",
            requiredPermissions: ["admin_view"]
          },
          {
            title: "Features",
            link: "/feature_flags",
            requiredPermissions: ["feature_flag_view"]
          },
          {
            title: "APIs",
            link: "/apis",
            requiredPermissions: ["admin_view"]
          },
          {
            title: "Cache",
            link: "/cache",
            requiredPermissions: ["cache_view"]
          },
          {
            title: "Users",
            link: "/users",
            requiredPermissions: ["users_view"]
          }
        ]}
        title="Configurations"
      />
    </div>
  );
};

// {renderItem("dashboard", "", "Dashboard")}
// {renderPermittedItem("attendees", "participants", "Participants")}
// {renderPermittedItem(
//   "communications",
//   "communications",
//   "Communications"
// )}
// {renderItemSessions("sessions", "sessions", "Sessions")}
// {renderPermittedItem("forms", "forms", "Form Builder")}
// {renderPermittedItem("housing", "housing", "Housing")}
// {renderItemMeetings("meetings", "meetings", "Meetings")}
// {renderPermittedItem("ticketing", "ticketing", "Ticketing")}
// {renderPermittedItem("configurations", "configurations", "Configurations")}

export default EventMenu;
