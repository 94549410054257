import React from "react";
import EventAnalyticsNewrelicRpm from "./EventAnalyticsNewrelicRpm";

const EventAnalyticsNewrelic = () => {
  const renderAnalytics = () => {
    return (
      <div>
        <EventAnalyticsNewrelicRpm />
      </div>
    );
  };

  return (
    <div>
      <div className="sg-mgmt-content-header">
        <h1>New Relic</h1>
      </div>
      <div>{renderAnalytics()}</div>
    </div>
  );
};

export default EventAnalyticsNewrelic;
