import React, { useState } from "react";
import SubpageTab from "@shared/SubpageTab";
import EventAnalyticsCharts from "./charts/EventAnalyticsCharts";
import EventAnalyticsGoogle from "./google/EventAnalyticsGoogle";
import EventAnalyticsReports from "./reports/EventAnalyticsReports";
import EventAnalyticsNewrelic from "./newrelic/EventAnalyticsNewrelic";
import TabbedPage from "@shared/TabbedPage";
const EventAnalytics = () => {

  return (
    <TabbedPage
      tabs={[
        { label: "Charts", component: EventAnalyticsCharts },
        { label: "Reports", component: EventAnalyticsReports },
      ]}
    />
  );
};

export default EventAnalytics;
