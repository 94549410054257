import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import urljoin from "url-join";
import EventContext from "@event/EventContext";
import { alertHttpError } from "@shared/Alerts";
import Loading from "@shared/Loading";
import EventAnalyticsGoogleActiveUsers from "./EventAnalyticsGoogleActiveUsers";
import EventAnalyticsGoogleSessions from "./EventAnalyticsGoogleSessions";
import EventAnalyticsGoogleSessionDuration from "./EventAnalyticsGoogleSessionDuration";

const EventAnalyticsGoogle = () => {
  const { apiRoot } = useContext(EventContext).values;
  const [token, setToken] = useState(null);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "analytics/token"));
        setToken(result.data.token);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchToken();
  }, []);

  const renderAnalytics = () => {
    if (fetched) {
      return (
        <div>
          <EventAnalyticsGoogleSessions token={token} />
          <EventAnalyticsGoogleSessionDuration token={token} />
          <EventAnalyticsGoogleActiveUsers token={token} />
        </div>
      );
    }
    return <Loading />;
  };

  return (
    <div>
      <div className="sg-mgmt-content-header">
        <h1>Google Analytics</h1>
      </div>
      <div>{renderAnalytics()}</div>
    </div>
  );
};

export default EventAnalyticsGoogle;
